import React from 'react';
import { motion } from 'framer-motion';

export const HomePage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-center px-4">
      <motion.img
        src="https://tastech.fr/wp-content/uploads/2024/11/k-mpar-high-resolution-logo-transparent.png"
        alt="Logo de Komparzon"
        className="h-16 w-auto mb-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      />
      
      <motion.h1
        className="text-3xl font-bold text-gray-900 mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        Site en maintenance
      </motion.h1>
      
      <motion.p
        className="text-gray-600 max-w-md"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        Nous travaillons actuellement à l'amélioration de notre site.
        Merci de votre patience, nous serons bientôt de retour !
      </motion.p>
      
      <motion.div
        className="mt-8 flex items-center justify-center space-x-3"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
      >
        <div className="h-4 w-4 bg-blue-500 rounded-full animate-bounce [animation-delay:-0.2s]"></div>
        <div className="h-4 w-4 bg-blue-500 rounded-full animate-bounce [animation-delay:-0.1s]"></div>
        <div className="h-4 w-4 bg-blue-500 rounded-full animate-bounce"></div>
      </motion.div>
    </div>
  );
};
